import React, { useEffect, useLayoutEffect, useState } from "react";
import Stats from "../components/Stats";
import FeaturedCauses from "../components/FeaturedCauses";
import DonationCard from "../components/DonationCard";
import axios from "axios";

const Causes = () => {
  const active = "bg-white-500 text-black-500";
  const inactive = "bg-white-500 text-white";
  const [One, setOne] = useState(inactive);
  const [Two, setTwo] = useState(inactive);
  const [Three, setThree] = useState(active);
  const [User, setUser] = useState({ session: null });
  const [UserDonations, setUserDonations] = useState([]);
  // useEffect(() => {
  //   window.scrollTo(0, 0);
  //   const checkSession = async () => {
  //     const res = await axios.get("http://localhost:8000/user/login", {
  //       withCredentials: true,
  //     });
  //     setUser(res.data);
  //     console.log(User, "cause");
  //     return User;
  //   };

  //   checkSession();

  //   console.log(User, "fdfdfdfdfrjieurireuieru");
  // }, []);

  return (
    <div className="mb-[120px] flex flex-col items-center">
      <div
        className="flex justify-center items-start w-full h-[320px] bg-cover bg-no-repeat bg-right md:bg-center p-5"
        id="causes-banner"
      >
        <div className="flex flex-col w-[90%] md:w-[50%] h-full justify-around text-center text-gray-100">
          <h1 className="text-6xl tracking-tighter font-sans">Our Programs</h1>
          <p className="text-gray-200" style={{ fontSize: "larger" }}>
            We're dedicated to nurturing young players, providing expert
            coaching, and fostering personal growth both on and off the field.
            Elevate your game and your future with us! With a focus on teamwork,
            discipline, and perseverance, we aim to build not just exceptional
            athletes, but well-rounded individuals. Join us and be part of a
            community that champions excellence and lifelong success.
          </p>
        </div>
      </div>

      <div className="flex w-full items-center flex-col mt-10">
        <div className="md:w-[80%] w-[98%] flex items-start">
          <h1 className="text-3xl font-bold py-5">Football is life</h1>
        </div>

        <div className="flex flex-col md:w-[80%] w-[98%] border-2 border-black items-center">
          {/* <img
            src="assets/causes-edu.jpg"
            className="w-full h-[200px] md:h-[500px]"
          /> */}
          <div className="w-full">
            <div className="w-full h-20 bg-yellow-500 flex" id="tabs">
              <button
                className={
                  "border-0 py-2 bg-yellow-500 px-6 focus:outline-none hover:bg-white hover:text-black-500 rounded-none text-lg " +
                  Three
                }
                bg-white
                text-black-500
                onClick={() => {
                  setOne(inactive);
                  setTwo(inactive);
                  setThree(active);
                }}
              >
                Skills Enhancement Sessions
              </button>
              <button
                className={
                  "border-0 py-2 bg-yellow-500 px-6 focus:outline-none hover:bg-white hover:text-black-500 rounded-none text-lg " +
                  Two
                }
                onClick={() => {
                  setOne(inactive);
                  setTwo(active);
                  setThree(inactive);
                }}
              >
                Comprehensive Development Workshops
              </button>
              <button
                className={
                  "border-0 py-2 bg-yellow-500 px-6 focus:outline-none hover:bg-white hover:text-black-500 rounded-none text-lg " +
                  One
                }
                onClick={() => {
                  setOne(active);
                  setTwo(inactive);
                  setThree(inactive);
                }}
              >
                Community Engagement and Support
              </button>
            </div>

            <div className="w-full">
              {One == active && <Stats User={User} />}
              {Two == active && <FeaturedCauses />}
              {Three == active && <DonationCard User={User && User.session} />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Causes;
