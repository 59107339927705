import axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";

const Stats = ({ donation, User }) => {
  const [UserDonations, setUserDonations] = useState([]);

  // useEffect(() => {
  //   const getDonations = async () => {
  //     const res = await axios.get(
  //       `http://localhost:8000/donations/${User.session.username}`,
  //       {
  //         withCredentials: true,
  //       }
  //     );
  //     setUserDonations(res.data.reverse());
  //     console.log(User.session.username, "donation");
  //   };
  //   getDonations();
  // });
  return (
    <div className="w-full p-4">
      <div
        className="m-div-1"
        style={{ backgroundImage: "none", transform: "none" }}
      >
        <p style={{ width: "50%", color: "black" }}>
          Opportunity for community involvement, academic assistance, and
          guidance. We provide a supportive network that encourages players to
          give back to their communities and thrive academically. Our mentorship
          programs ensure that every athlete receives the resources and support
          needed to excel both in their studies and personal development. Join
          us to become a well-rounded individual, prepared for future challenges
          and opportunities.
        </p>
        <div
          className="div-1-img"
          style={{ height: "40vh", width: "30vw", backgroundColor: "aqua" }}
        ></div>
      </div>
    </div>
  );
};

export default Stats;
