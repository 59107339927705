import axios from "axios";
import React, { useEffect, useState } from "react";
import FeaturedCard from "./FeaturedCard";

const FeaturedCauses = () => {
  // const [data, setdata] = useState([]);
  // useEffect(() => {
  //   const getCauses = async () => {
  //     const res = await axios.get("http://localhost:8000/causes");
  //     console.log(res.data);
  //     setdata(res.data);
  //   };
  //   getCauses();
  // }, []);
  return (
    <>
      {/* <p>
        FEATURED <span style={{ color: "maroon" }}>CAUSES</span>
      </p> */}

      <div
        className="m-div-1"
        style={{ backgroundImage: "none", transform: "none" }}
      >
        <div
          className="div-2-img"
          style={{ height: "40vh", width: "30vw", backgroundColor: "aqua" }}
        ></div>
        <p style={{ width: "50%", color: "black" }}>
          Holistic training covering physical fitness, tactical understanding,
          mental resilience, and essential life skills. Our comprehensive
          approach ensures athletes develop not only on the field but also in
          their personal lives. By emphasizing character building and emotional
          intelligence, we prepare our players for success in all aspects of
          life.
        </p>
      </div>
    </>
  );
};

export default FeaturedCauses;
