import React, { useLayoutEffect } from "react";
import NewsCard from "../components/NewsCard";

const News = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    console.log("fdfddf");
  }, []);
  return (
    <div className="flex flex-col mb-[120px]">
      <div
        className="flex justify-center items-start w-full h-[320px] bg-cover bg-no-repeat bg-right md:bg-center p-5"
        id="about-banner"
      >
        <div className="flex flex-col w-[90%] md:w-[50%] h-full justify-around text-center text-gray-100">
          <h1 className="text-6xl tracking-tighter  font-sans">News</h1>
          <p className="text-gray-200" style={{ fontSize: "larger" }}>
            With a holistic approach that covers physical fitness, tactical
            understanding, mental resilience, and essential life skills, the
            foundation is committed to developing well-rounded individuals.
            Additionally, it offers opportunities for community involvement,
            academic assistance, and mentorship, ensuring that each athlete is
            equipped for success in all aspects of life.
          </p>
        </div>
      </div>

      <NewsCard />
    </div>
  );
};

export default News;
